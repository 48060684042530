@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,700,800);
.main-menu{
    border-bottom:8px solid #004f81
}
.main-menu .content{
    display:flex;
    margin:0 auto;
    max-width:90%;
    justify-content:space-between;
    overflow:hidden;
    width:960px;
    align-items: center;
}
.main-menu .content h1{
    align-items:center;
    display:flex;
    margin:0;
    height:110px
}
.main-menu .content h1 img{
    max-width:100%;
    width:300px
}
@media (max-width:768px){
    .main-menu .content .payments{
        display:none
    }
}
footer{
    border-top:4px solid #004f81;
    
}
.contact .content,footer .payments{
    margin:0 auto;
    max-width:90%;
    justify-content:space-between;
    width:960px
}
.contact .content h3{
    color:#005a64;
    font-size:2.8em;
    font-weight:400;
    margin:16px 0 8px;
    padding-top:16px
}
.contact .content ul{
    display:flex;
    flex-wrap:wrap;
    padding:0 0 16px 20px
}
.contact .content li{
    flex:0 1 25%;
    font-size:1.3em;
    font-weight:lighter
}
footer .payments{
    text-align: center;
    display:flex;
   
}
footer .payments>div{
    align-items:flex-start;
    display:flex;
    flex:1 1;
    flex-direction:column;
    margin:16px 0;
    margin-left: auto;
    margin-right: auto;
}
footer .payments .credit-cards{
    border-left:2px solid #004f81;
    border-right:2px solid #004f81;
    margin-right:30px;
    padding-left:30px
}
footer .payments>div h4{
    color:#005a64;
    font-size:2.6em;
    font-weight:400;
    margin:8px 0
}
footer .payments>div img{
    max-width:300px
}
@media (max-width:960px){
    .contact .content li{
        flex:0 1 30%
    }
    footer .payments{
        flex-wrap:wrap
    }
    footer .payments .credit-cards,footer .payments .security{
        flex:0 1 45%
    }
    footer .payments .credit-cards{
        border-right:none;
        margin-right:0
    }
    footer .payments .logo{
        align-items:center;
        border-top:2px solid #004f81;
        padding-top:16px
    }
}
@media (max-width:650px){
    .contact .content li{
        flex:0 1 45%
    }
    footer .payments .credit-cards,footer .payments .security{
        align-items:center;
        flex:0 1 100%
    }
    footer .payments .credit-cards{
        border-top:2px solid #004f81;
        border-left:none;
        padding-top:16px
    }
}
@media (max-width:450px){
    .contact .content li{
        flex:0 1 100%
    }
}
#client-info{
    
    display:flex;
    text-align: center;
    padding-left: 20px;
    padding-bottom: 20px;
    padding-top: 20px;
    -webkit-box-shadow: 10px 10px 20px -11px rgba(0,0,0,0.75);
    -moz-box-shadow: 10px 10px 20px -11px rgba(0,0,0,0.75);
    box-shadow: 10px 10px 20px -11px rgba(0,0,0,0.75);
    border-radius: 10px;
    margin-bottom: 10px;
    
}
#client-info .client,#client-info .consultant,#client-info .order{
    flex:1 1;
    border-spacing:10px;
    
    

}
#client-info .client h4,#client-info .consultant h4,#client-info .order h4{
    color:#023854;
    font-size:2.8em;
    font-weight:400;
    margin:0 0 10px;
    font-weight: bold;

 
}
#client-info .info{
    color:#000;
    font-size:1.2em;
    
    
}
#client-info .info strong{
    margin-right:5px;
    font-weight: bold;
}
#client-info .consultant{
    border-left:2px solid #004f81;
    border-right:2px solid #004f81;
    margin-right:50px;
    text-align: center;
    

  
}
#client-info .order-number{
font-size: 18px;
font-weight: bold;


}
@media (max-width:1024px){
    #client-info .consultant{
        margin-left:20px;
        margin-right:20px;
        max-width:none;
        padding:0 20px
    }
}
@media (max-width:840px){
    #client-info{
        flex-wrap:wrap
    }
    #client-info .client,#client-info .consultant{
        flex:0 1 45%
    }
    #client-info .consultant{
        margin-left:0;
        margin-right:0;
        padding:0 0 0 5%;
        border-right:none
    }
    #client-info .order{
        flex:1 1;
        border-top:2px solid #008136;
        margin-top:16px;
        padding-bottom:16px;
        padding-top:8px;

        
    }
}
@media (max-width:500px){
    #client-info .client,#client-info .consultant,#client-info .order{
        flex:0 1 100%
    }
    #client-info .consultant{
        border-top:2px solid #004f81;
        margin-top:16px;
        padding-top:8px;
        padding-left:0;
        border-left:none
    }
    #client-info .order{
        margin-top:16px;
        padding-bottom:16px;
        padding-top:8px
    }
}
#credit-card-info{
    font-weight: bold;
    margin:16px 0;
    box-shadow: -1px 8px 11px -3px rgba(0,0,0,0.75);
    border-radius: 10px;
}


#credit-card-info h3,.contract h3{
    color:#023854;
    font-size:2.8em;
    font-weight:bold;
    margin:0 0 10px
}

#credit-card-info .qtd-buttons{
    justify-content:center;
    display:flex;
  
    
}
#credit-card-info .qtd-buttons span{
    background-color:#efefef;
    border:1px solid #ccc;
    flex:1 1;
    cursor:pointer;
    font-size:12px;
    padding:10px 16px;
    border-radius:20px
}
#credit-card-info .qtd-buttons span.active{
    background-color:#fff;
    border-bottom-color:#fff;
    cursor:default
}
#credit-card-info .qtd-buttons span:first-child{
    border-radius:4px 0 0 0;
    border-right:none
}
#credit-card-info .qtd-buttons span:last-child{
    border-radius:0 4px 0 0
}
.contract label{
    font-size:16px
}
.contract label span{
    color:#00f;
    cursor:pointer;
    text-decoration:underline
}
#contract{
    display:none
}
#contract.visible{
    background:rgba(0,0,0,.5);
    display:block;
    position:fixed;
    z-index:1000;
    top:0;
    bottom:0;
    left:0;
    right:0
}
#contract .wrapper{
    background:#fff;
    border-radius:8px;
    margin:30px 60px;
    height:calc(100vh - 120px);
    overflow:hidden
}
#contract .wrapper .buttons{
    display:flex;
    justify-content:space-between
}
#contract .content{
    padding:30px;
    font-size:16px;
    overflow:auto;
    height:calc(100% - 112px);
    white-space:pre-line
}
#contract p{
    cursor:pointer;
    font-size:16px;
    margin:10px 0 0;
    text-align:right;
    top:-10px;
    padding:10px 30px;
    position:relative
}
#credit-card-info .credit-card{
    display:flex;
    border:1px solid #ccc;
    border-radius:0 0 4px 4px;
    border-top:0;
    padding:20px
}
#credit-card-info .credit-card .form{
    flex:1 1;
    margin-left:50px;
    max-width:330px
}
#credit-card-info .credit-card .group-form{
    display:flex;
    justify-content:space-between
}
#credit-card-info .credit-card .form-control{
    display:flex;
    flex-direction:column
}
#credit-card-info .form .hidden{
    display:none
}
#credit-card-info .form-control label{
    font-size:1.2em;
    margin-bottom:5px
}
#credit-card-info .form-control input{
    font-size:1.2em;
    border-radius:4px;
    border:1px solid #ccc;
    line-height:30px;
    margin-bottom:5px;
    padding:0 8px
}
#credit-card-info .form-control input:disabled{
    background-color:#e6e6e6
}
#credit-card-info .form-control input.error{
    border-color:#d91b5b;
    outline-color:#d91b5b
}
#credit-card-info .submit-payment{
    display:flex;
    justify-content:flex-end;
    margin-top:10px
}
#credit-card-info .success-msg{
    background-color:#27ae60;
    border-radius:2px;
    color:#fff;
    font-size:1.3em;
    padding:5px 16px
}
#credit-card-info .error-msg{
    background-color:#e74c3c;
    border-radius:2px;
    color:#fff;
    font-size:1.1em;
    margin:0 15px 0 0;
    padding:5px 16px
}
#credit-card-info .btn-submit{
    background-color:#27ae60;
    border:none;
    border-radius:4px;
    color:#fff;
    font-size:1.6em;
    height:40px;
    padding:0 20px;
    -webkit-transition:background-color .6s;
    transition:background-color .6s
}
#credit-card-info .btn-submit:hover{
    background-color:#2ecc71
}
#credit-card-info .btn-submit:disabled{
    background-color:#ccc
}
#credit-card-info .payment-info{
    align-items:center;
    display:flex;
    font-size:1.3em;
    margin-top:16px;
    padding:20px
}
#credit-card-info .payment-info img{
    margin-right:16px
}
@media (max-width:650px){
    #credit-card-info .credit-card{
        flex-direction:column
    }
    #credit-card-info .credit-card .form{
        margin-left:0;
        margin-top:16px;
        max-width:100%
    }
}
#order-info h3{  
    color:#023854;
    font-size:2.8em;
    font-weight:bold;
    margin:0 0 10px
}


#order-info thead tr th{
    width: 400px;
    height: 30px;
   
    font-size:1.2em;
    line-height:2.2em;
    background-color:#3969b1;
    border-radius: 20px;
    font-size: 15px;
    color: white;
    font-weight: bold;
    border: 2px solid #000000;

    
}
#order-info .order{
    border-spacing:10px;
    width:100%;
}

#order-info tbody tr td{
    width: 400px;
    height: 30px;
    font-size:1.2em;
    line-height:2.2em;
    background-color:#efefef;
    border-radius: 20px;
    font-size: 15px;
    color: black;
    font-weight: bold;
    text-align: center;
    box-shadow: 5px 5px 10px #00000065;
    
}



#product-info{
    margin-top: 10px;
}
#product-info h3{
    color:#023854;
    font-size:2.8em;
    font-weight:bold;
    margin:0 0 10px
    
}
#product-info .products{
    border-spacing:10px;
    width:100%;
    
}

#product-info .products thead th {
    width: 400px;
    height: 30px;
    font-size: 1.2em;
    line-height: 2.2em;
    background-color: #3969b1;
    border: 2px solid #000000;
    background-size: contain;
    border-radius: 20px;
    font-size: 15px;
    color: rgb(255, 255, 255);
    font-weight: bold;
   
}


#product-info .products tbody td{
    width: 400px;
    height: 30px;
    font-size:1.2em;
    line-height:18px;
    background-color:#efefef;
    border-radius: 20px;
    font-size: 12px;
    color: black;
    font-weight: bold;
    text-align: center;
    border: 2px solid #ccc;
    letter-spacing: 1px;
    
    
}

#product-info .total{
    color:#005d83;
    font-size:2.5em;
    margin-top:16px;
    text-align:right
}
#product-info .total strong{
    margin-right:5px
}
#product-info .payment-info{
    align-items:center;
    border:1px solid #ccc;
    display:flex;
    font-size:.8em;
    margin-top:16px;
    padding:20px
}
#product-info .payment-info img{
    margin-right:16px
}
#payment{
    height:100vh
}
#payment main{
    margin:0 auto;
    max-width:90%;
    width:960px
}
#payment h2{
    font-size:4em
}
#payment h2,#payment h5{
    color:#023854;
    font-weight:400;
    margin:16px 0
}
#payment h5{
    font-size:2em
}
#payment h6{
    color:#023854;
    font-size:1.6em;
    font-weight:400;
    margin:16px 0
}
#payment .error h5,#payment .error h6{
    color:#d91b5b
}
#payment .loading{
    height:calc(100% - 489px);
    margin:0 auto;
    max-width:90%;
    width:960px
}
#payment .paid-content{
    display:flex;
    justify-content:flex-end
}
#payment .paid-content h6{
    background-color:green;
    border-radius:8px;
    color:#fff;
    font-size:2.8em;
    margin-top:0;
    padding:5px 16px
}
.content{
    padding:30px;
    font-size:16px;
    overflow:auto;
    height:calc(100% - 112px);
    white-space:pre-line
}
body{
    margin:0;
    padding:0;
    font-size:10px;
    font-family:Open Sans,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
    -webkit-font-smoothing:antialiased;
    -moz-osx-font-smoothing:grayscale
}
code{
    font-family:source-code-pro,Menlo,Monaco,Consolas,Courier New,monospace
}
@media (max-width:768px){
    body{
        font-size:8px
    }
}
/*# sourceMappingURL=main.22397908.chunk.css.map */
